.nav__searchbar__results {
    width: 89.5%;
    max-height: 300px;
    position: absolute;
    top: 40px;
    z-index: 4;
    overflow-y: auto;
    // padding: 5px;
    padding: 10px 5px 5px 5px;
    // border: 1px solid rgba(33, 33, 33, 0.1);
    border-top: none;
    //box-shadow: 0 6px 5px 0 rgba(0,0,0,0.1);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: white;
    display: none;

    .list-group {
        padding: 0 0 0 0;
        list-style: none;
        cursor: pointer;
    }
    .list-group-item {
        padding: 10px;
    }
    .list-group-item:hover {
        background-color: rgba(200, 200, 200, 0.3);
    }
    .list-group-item .highlight {
        background-color: #e6fd02;
    }
    .list-group-nav {
        .nav-item {}
        .nav-item:hover {
            background-color: rgba(200, 200, 200, 0.3);
        }
    }
}
